@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    display: flex;
}

h1 {
    color: rgb(171, 191, 209);
    font-family: Arial, Helvetica, sans-serif;
}

h2 {
    font-family: 'Times New Roman', Times, serif;
}

.form-text {
    @apply w-full md:w-2/3 xl:w-1/3 border border-slate-700 rounded-md m-2 p-2;
}

.form-label{
    @apply w-28 text-2xl
}